import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
  } from '@chakra-ui/react'
  import { Link, useLocation } from 'react-router-dom'
  
  export const BreadCrumb = () => {
    const { pathname } = useLocation()
  
    const items = [
      {
        title: 'Minha Conta / Gerência de Usuário',
        path: '/users',
      },
      {
        title: 'Minha Conta / Cadastro de Usuário',
        path: '/users/create',
        breadCrumbs: [
          {
            title: 'Gerência de Usuário',
            route: '/users',
          },
        ],
      },
      {
        title: 'Minha Conta / Edição de Usuário',
        path: '/users/editing',
        breadCrumbs: [
          {
            title: 'Gerência de Usuário',
            route: '/users',
          },
        ],
      },
      {
        title: 'Analítico',
        path: '/analytics',
      },
      {
        title: 'Analítico PRO',
        path: '/analytics-pro',
      },
      {
        title: 'Importa Fácil',
        path: '/importa-facil',
      },
      {
        title: 'Clube contador',
        path: '/clube-contador',
      },
      {
        title: 'Quod',
        path: '/quod',
      },
      {
        title: 'Gerência de Arquivos',
        path: '/files',
      },
      {
        title: 'Gerência de Arquivos - Relatórios',
        path: '/files/relatorios',
      },
      {
        title: 'Gerência de Arquivos - Enviados',
        path: '/files/enviados',
      },
      {
        title: 'Gerência de Arquivos - Impressão',
        path: '/files/impressao',
      },
      {
        title: 'Minha Conta',
        path: '/company-settings/geral',
      },
      {
        title: 'Minha Conta',
        path: '/company-settings/certificate',
      },
      {
        title: 'Minha Conta',
        path: '/company-settings/plan',
      },
      {
        title: 'Minha Conta',
        path: '/company-settings/charge',
      },
      {
        title: 'Meus Clientes',
        path: '/participants',
      },
      {
        title: 'Loja de Apps: Integração',
        path: '/store/integrations',
      },
      {
        title: 'Loja de Apps: Simplaz',
        path: '/store/integrations/simplaz',
      },
      {
        title: 'Loja de Apps: Importa Fácil',
        path: '/store/integrations/IMPXLS',
      },
      {
        title: 'Loja de Apps: Mercado Livre',
        path: '/store/integrations/mercadolivre',
      },
      {
        title: 'Loja de Apps: Analytics BI',
        path: '/store/integrations/analyticsbi',
      },
      {
        title: 'Loja de Apps: Analytics Pro Inteligência de Vendas',
        path: '/store/integrations/intelvendas',
      },
      {
        title: 'Loja de Apps: Analytics Pro Inteligência Administrativa',
        path: '/store/integrations/inteladm',
      },
      {
        title: 'Loja de Apps: Ferramentas',
        path: '/store/tools',
      },
      {
        title: 'Gerência de Meus Clientes',
        path: '/participants/management',
        breadCrumbs: [
          {
            title: 'Meus Clientes',
            route: '/participants',
          },
        ],
      },
    ]
  
    let item = items.find((f) => f.path === pathname)
  
    return (
      <>
        <Box>
          <Text fontSize="md" fontFamily={'title'}>{item?.title || 'Início'} </Text>
          <Breadcrumb fontSize="xs" color="primary.pure">
            {item && (
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/initial">
                  <Text fontSize="sm"> Início </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {!!item?.breadCrumbs &&
              item?.breadCrumbs.map((bread, index) => (
                <BreadcrumbItem
                  key={index}
                  isLastChild={!bread.route}
                  color="primary.pure"
                >
                  <BreadcrumbLink
                    as={Link}
                    to={bread.route || ''}
                    isCurrentPage={!bread.route}
                  >
                    {bread!?.title}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ))}
          </Breadcrumb>
        </Box>
      </>
    )
}  
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { PlusCircle, Question } from 'phosphor-react'
import { FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FormInput } from 'src/components/FormInput'
import { FormSelect } from 'src/components/FormSelect'
import { setupProfile } from 'src/constants/setupProfile'
import { useSession } from 'src/contexts'
import { User } from '@/screens/Company-settings/Users/hooks'
import {
  apiInstance,
  getAplicativosByCompanyDocument,
  updateUserAppTag,
} from 'src/services/api'
import {
  InputFormattingPattern,
  unFormatting,
} from 'src/utilities/formattingToPattern'
import {
  getEmailNewUserNeedAuthorization,
  getEmailUserLocalWithOpenAccount,
  getEmailWarningAdminUserCreated,
} from 'src/utilities/getEmailHtml'
import { sendBase64ForEmails } from 'src/utilities/sendBase64ForEmails'
import * as yup from 'yup'
import {
  GestorProfiles,
  UniqueProfiles,
  WorkspaceProfiles,
} from '../../../../constants/profiles'
import { appsWithPermissions } from '../utils/appsWithPermissions'
import {
  createdHashRememberPassword,
  createdPasswordDefault,
} from '../utils/createdPasswordDefault'
import { GetIconApp } from '../utils/getIconApp'
import { getProfileName } from '../utils/getProfileName'

export interface UserProps {
  name: string
  email: string
  phone: string
  password: string
  re_password?: string
  status?: string
  perfilWs?: string
}

export interface profileApps {
  Aplicativo: string
  Perfil: string | number
}

export const schema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
})

export const CreateUser = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()

  const { session } = useSession()

  const { profiles, helpLink } = setupProfile({ origin: session.origin })
  const [apps, setApps] = useState<any[]>([])
  const [appsSelected, setAppsSelected] = useState<any[]>([])
  const [user, setUser] = useState<User>({} as User)
  const [term, setTerm] = useState(false)
  const [profileApps, setProfileApps] = useState<profileApps[]>([])

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<UserProps>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  })

  const createUser: SubmitHandler<UserProps> = async (values) => {
    try {
      const perfilApps = profileApps.map((app) => {
        return {
          Aplicativo: app.Aplicativo,
          Perfil: app.Perfil,
        }
      })

      delete values.re_password
      delete values.status
      const userData = {
        ...values,
        email: values.email
          .toLowerCase()
          .replace(/[&/\\#,+()$~%'":*?<>{}!]/g, ''),
        companyCnpjCpf: session.company.document,
        firstOrigin: session.origin,
        perfilWs: Number(values.perfilWs),
        cpf: '',
        phone: unFormatting(values.phone),
        parentUserId: session.id,
        type: session.type,
        specific: session.specific,
        perfilAplicativos: JSON.stringify(perfilApps),
        password: createdPasswordDefault(session.company.document),
      }

      // type 0 => local
      const user =
        session.type === 0
          ? {
              ...userData,
              status: true,
            }
          : userData

      const { data } = await apiInstance(8006).post('/UserGestor', user, {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session?.company?.id.toString(),
          cnpjcpf: session?.company?.document,
        },
      })

      // NOTIFICAÇÃO DE USUARIO VALIDADO COM SUCESSO PELO CS
      // await apiInstance(8006).get(
      //   `User/NotifyUserValid/${session.company.id}`,
      //   {
      //     headers: {
      //       emailUser: user.email,
      //     },
      //   }
      // )
      setUser(data)

      const hashRemenberPassword = await createdHashRememberPassword(user.email)

      handleClickAddUsuario(data, values.phone, hashRemenberPassword)

      toast({
        title: 'Usuário criado!',
        description: 'Usuário criado com sucesso!',
        variant: 'solid',
        status: 'success',
        position: 'top',
      })
      navigate('/users')
    } catch (err: any) {
      if (err.response?.data === 'User not save. Verify e-mail used') {
        toast({
          title: 'Falha ao cadastrar!',
          description: 'E-mail já utilizado',
          status: 'error',
          isClosable: true,
          position: 'top',
        })
        return
      }
      toast({
        title: 'Falha ao cadastrar!',
        description:
          'Não foi possível cadastrar o novo usuário. Tente novamente mais tarde',
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
  }

  async function handleClickAddUsuario(
    user: User,
    phone: string = '',
    hashRememberPassowrd: string = '',
  ) {
    const environmentIsDevelopment =
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('alpha') ||
      window.location.origin.includes('192.168.0.221')
        ? true
        : false

    try {
      updateUserAppTag({
        userId: user.userId,
        userApps: user.aplicativos ?? '',
        appTag: amountStringApps(appsSelected),
        action: 'create',
        createUser: true,
      })

      const nameApps = appsSelected.map((app) => getNameByApp(app))
      const emailsAdmin = getEmailAdmin()

      const htmlFileAtivacaoUser = getEmailNewUserNeedAuthorization(user.name)

      const htmlFileNotifyAdmin = getEmailWarningAdminUserCreated({
        cadastradoPor: session.email,
        nome: user.name,
        email: user.email,
        cnpj: session.company.document,
        empresa: session.company.name,
        telefone: phone,
        statusUser: user.status === true ? 'Ativo' : 'Inativo',
      })
      sendBase64ForEmails({
        emails: environmentIsDevelopment
          ? ['rafaelguilber.londrisoft@gmail.com', 'renan.londrisoft@gmail.com']
          : emailsAdmin,
        htmlFile: htmlFileNotifyAdmin,
      })

      const link = `${window.location.origin}/recovery?hash=${hashRememberPassowrd}`
      if (session.type === 0) {
        const htmlIsNotifyUserAcessoLiberadoLocal =
          getEmailUserLocalWithOpenAccount(
            user.name,
            session.company.name,
            session.company.document,
            user.email,
            link,
            true,
          )

        sendBase64ForEmails({
          emails: [user.email],
          htmlFile: htmlIsNotifyUserAcessoLiberadoLocal,
        })
      }

      // excluir esse trecho de codigo quando for feito isso no nucleo
      if (session.type !== 0) {
        const link = `${window.location.origin}/recovery?hash=${hashRememberPassowrd}`

        const htmlIsNotifyUserAcessoLiberadoLocal =
          getEmailUserLocalWithOpenAccount(
            user.name,
            session.company.name,
            session.company.document,
            user.email,
            link,
            false,
          )

        sendBase64ForEmails({
          emails: [user.email],
          htmlFile: htmlFileAtivacaoUser,
        })

        sendBase64ForEmails({
          emails: [user.email],
          htmlFile: htmlIsNotifyUserAcessoLiberadoLocal,
        })
      }
      // excluir ate aqui
    } catch (error) {
      console.log(error)
    }
    onClose()
  }

  const amountStringApps = (appsSelected: string[]): string => {
    const appsConverterToString = JSON.stringify(appsSelected)
    let appsConverterToStringReplace = appsConverterToString
      .replaceAll(',', '|')
      .replaceAll('"', '')
      .replaceAll('[', '')
      .replaceAll(']', '')

    return `|${appsConverterToStringReplace}|`
  }

  const getAppsByCompany = async () => {
    const { data } = await getAplicativosByCompanyDocument(
      session.company.document,
      session.origin,
    )

    setApps(data.filter((app: any) => app.indAtivar === 'S'))
  }

  const getNameByApp = (appTag: string) => {
    const app = apps.find((app: any) => app.tag === appTag)
    return app?.nome as string
  }

  const getEmailAdmin = (): string[] => {
    const emails = session.plan.users
    const onlyEmailAdmin = JSON.parse(emails).map(
      (user: any) => user.perfilWs === 1 && user.email,
    )

    return onlyEmailAdmin.filter((user: any) => user !== false)
  }

  const handlePatternNameApp = (appTag: string): string => {
    if (appTag.includes('SP_') || appTag.includes('SIMPLAZ')) return 'SIMPLAZ'
    if (appTag.includes('GE_') || appTag.includes('GESTOR')) return 'GESTOR'
    if (appTag.includes('UN_') || appTag.includes('UNIQUE')) return 'UNIQUE'

    return ''
  }

  const handleSelectApp = (appTag: string) => {
    if (handlePatternNameApp(appTag) !== '') {
      appSelectIsNotPermissions(appTag, 'UPDATE')
      return
    }

    if (appsSelected.includes(appTag)) {
      appSelectIsNotPermissions(appTag, 'DELETE')
    } else {
      appSelectIsNotPermissions(appTag, 'INSERT')
    }
  }

  const appSelectIsNotPermissions = (
    appTag: string,
    option: 'INSERT' | 'DELETE' | 'UPDATE',
  ) => {
    switch (option) {
      case 'INSERT':
        setAppsSelected((prevState: any[]) => {
          return [...prevState, appTag]
        })
        break

      case 'DELETE':
        setAppsSelected((prevState: any[]) => {
          return prevState.filter((item) => item !== appTag)
        })
        appsWithPermissions.includes(appTag)
          ? setProfileApps((prevState) => {
              return prevState.filter(
                (profile) =>
                  profile.Aplicativo !== handlePatternNameApp(appTag),
              )
            })
          : console.log()

        break

      case 'UPDATE':
        setAppsSelected((prevState: any[]) => {
          return prevState.filter(
            (item) => item !== appTag && item !== 'GESTOR' && item !== 'UNIQUE',
          )
        })
        setAppsSelected((prevState: any[]) => {
          return [...prevState, appTag]
        })

        break
      default:
        return false
    }
  }

  const handleProfileAppsSelected = (
    appName: string,
    permission: string | number,
  ) => {
    const app = profileApps.find((app) => app.Aplicativo === appName)

    if (app) {
      setProfileApps((prevState) => {
        return prevState.filter((item) => item.Aplicativo !== appName)
      })
    } else {
      setProfileApps((prevState) => {
        return [...prevState, { Aplicativo: appName, Perfil: permission }]
      })
    }
  }

  useEffect(() => {
    getAppsByCompany()
  }, [])

  return (
    <>
      <Helmet>
        <title>Cadastro de Usuário | Workspace</title>
      </Helmet>
      <Flex
        as="form"
        onSubmit={handleSubmit(createUser)}
        w="full"
        bg="high.pure"
        borderRadius="4px"
        p="5"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        gap={10}
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
        }}
      >
        <Box w="full">
          <Text
            lineHeight="20px"
            mb="8px"
            fontSize="16px"
            fontFamily="Sora"
            color="rgba(22, 112, 215, 1)"
          >
            Dados do usuário
          </Text>
          <Text
            fontSize="16px"
            fontFamily="Sora"
            color="rgba(158, 158, 158, 1)"
          >
            Tenha independência e pague somente por usuários ativos.
          </Text>
          <Flex flexDirection="column" gap={8} mt="16px">
            <FormInput
              {...register('name')}
              error={errors.name}
              label="Nome"
              placeholder="john doe"
              isRequired
            />
            <FormInput
              {...register('email')}
              error={errors.email}
              label="E-mail"
              placeholder="mail@provider.com"
              isRequired
            />

            <FormInput
              {...register('phone')}
              label="Telefone"
              name="phone"
              placeholder="(00) 0 0000-0000"
              control={{ w: '50%' }}
              onInput={(e) =>
                InputFormattingPattern({ event: e, pattern: 'phone' })
              }
            />

            <Flex gap="4" flexDir="column">
              <FormSelect
                w="210px"
                defaultValue="1"
                label="Status"
                {...register('status')}
                isDisabled
              >
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </FormSelect>

              <FormSelect
                w="318px"
                label="Perfil"
                {...register('perfilWs')}
                defaultValue="2"
                hus={
                  <Link href={helpLink} target="_blank" ml="8px">
                    <Question size={18} />
                  </Link>
                }
              >
                <option value="0">Administrador</option>
                <option value="1">Usuário</option>
              </FormSelect>
            </Flex>
          </Flex>
        </Box>
        <Box w="full">
          <Text lineHeight="20px" mb="8px" color="primary.pure">
            Permissões de uso dos aplicativos
          </Text>
          <Text
            fontSize="16px"
            fontFamily="Sora"
            color="rgba(158, 158, 158, 1)"
          >
            Selecione os aplicativos que esse usuário terá acesso
          </Text>

          <Flex flexDirection="column" gap={8} mt="30px" w="185px">
            <Button
              leftIcon={<PlusCircle size={22} color="#1670D3" />}
              fontFamily="inter"
              fontSize={'14px'}
              fontWeight={'600'}
              color="#1670D3"
              bg="transparent"
              border="1px solid #1670D3"
              _hover={{
                border: '2px solid #1670D3',
                bg: '#fff',
                color: '#1670D3',
                transform: 'scale(1.02)',
              }}
              h="40px"
              borderRadius="8px"
              onClick={onOpen}
            >
              Ativar aplicativos
            </Button>
          </Flex>

          <Flex flexDir={'column'} mt="24px">
            <Text fontFamily="Sora" fontSize="14px" fontWeight={'400'}>
              Aplicativos ativos
            </Text>
            <Flex
              border="1px solid #CBD5DF"
              mt="4px"
              maxH="200px"
              borderTopLeftRadius={'4px'}
              borderTopRightRadius={'4px'}
              flexDir={'column'}
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  background: '#ddd',
                  width: '6px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#A1A1AA',
                  borderRadius: '24px',
                },
              }}
            >
              {appsSelected.length > 0 ? (
                appsSelected
                  .filter((app) => app !== 'GESTOR' && app !== 'UNIQUE')
                  .map((app) => (
                    <Flex
                      key={app.tag}
                      borderBottom="1px solid #CBD5DF"
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Flex flexDir="column" w="400px">
                        <Flex alignItems={'center'}>
                          <Flex>
                            <GetIconApp appTag={app} />
                          </Flex>
                          <Flex flexDir="column">
                            <Text
                              fontSize="14px"
                              fontFamily={'Sora'}
                              fontWeight={'400'}
                              color="#3D374B"
                            >
                              {getNameByApp(app)}
                            </Text>
                            {appsWithPermissions.includes(app) && (
                              <Text
                                fontSize="13px"
                                fontFamily={'Sora'}
                                fontWeight={'400'}
                                color="#666666"
                              >
                                {getProfileName(app, profileApps)}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>

                      <Button
                        color="rgba(240, 62, 62, 1)"
                        fontSize="14px"
                        fontFamily={'Sora'}
                        fontWeight={'400'}
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                        textDecorationLine={'underline'}
                        onClick={() => appSelectIsNotPermissions(app, 'DELETE')}
                      >
                        Desativar
                      </Button>
                    </Flex>
                  ))
              ) : (
                <Text
                  fontSize="15px"
                  fontFamily={'Sora'}
                  fontWeight={'400'}
                  color="#303030"
                  p="8px"
                >
                  Nenhum aplicativo selecionado
                </Text>
              )}
            </Flex>
          </Flex>

          <Text lineHeight="20px" mt="38px" mb="8px" color="primary.pure">
            Termos
          </Text>
          <Checkbox
            size="lg"
            isChecked={term}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              setTerm(e.currentTarget.checked)
            }
          >
            <Text fontSize="14px" marginLeft="12px">
              Estou ciente que a contratação de novos usuários gera cobrança
              adicional.
            </Text>
          </Checkbox>

          <Flex gap={10} justify="end">
            <Button
              type="submit"
              minW="156px"
              w="284px"
              h="40px"
              borderRadius="8px"
              color="#FFF"
              fontFamily="inter"
              fontSize={'16px'}
              fontWeight={'700'}
              bg="rgba(16, 153, 114, 1)"
              _hover={{ bg: 'rgba(16, 153, 114, 0.8)', opacity: 0.8 }}
              isDisabled={!term || appsSelected.length === 0}
              isLoading={isSubmitting}
              loadingText="Salvando usúario"
            >
              Criar usuário
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily="Sora"
            fontSize={'16px'}
            fontWeight={'600'}
            h="40px"
            borderBottom="1px solid #CBD5E0"
          >
            O que deseja ativar?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0">
            <Flex
              flexDir="column"
              overflow="auto"
              maxH="400px"
              key={'modalBody'}
              css={{
                '&::-webkit-scrollbar': {
                  background: '#ddd',
                  width: '6px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#A1A1AA',
                  borderRadius: '24px',
                },
              }}
            >
              {apps.map((app: any, index: number) => (
                <div key={index}>
                  {appsWithPermissions.includes(app.tag) ? (
                    <Accordion allowToggle>
                      <AccordionItem>
                        <AccordionButton
                          key={app.tag}
                          h="37px"
                          borderBottom="1px solid #CBD5E0"
                          p="0"
                          bg={
                            appsSelected.includes(app.tag) ? '#EAF0F9' : '#FFF'
                          }
                          color={'#303030'}
                          alignItems={'center'}
                          cursor="pointer"
                        >
                          <Flex justifyContent={'space-between'} w="100%">
                            <Flex alignItems={'center'}>
                              <GetIconApp appTag={app.tag} />
                              <Text
                                fontSize="14px"
                                fontFamily={'Sora'}
                                fontWeight={'400'}
                                color="#303030"
                              >
                                {app.nome}
                              </Text>
                            </Flex>
                            <Flex>
                              <AccordionIcon />
                            </Flex>
                          </Flex>
                        </AccordionButton>

                        {app.tag === 'SIMPLAZ' ||
                          (app.tag.includes('SP_') && (
                            <>
                              {WorkspaceProfiles.filter(
                                (app) => app.id !== '999',
                              ).map((workspace) => (
                                <AccordionPanel
                                  key={workspace.id}
                                  p="0"
                                  h="37px"
                                  borderBottom="1px solid #CBD5E0"
                                  bg={
                                    profileApps.some(
                                      (u) =>
                                        u.Aplicativo === 'SIMPLAZ' &&
                                        u.Perfil === workspace.id,
                                    )
                                      ? '#EAF0F9'
                                      : '#FFF'
                                  }
                                  color={'#303030'}
                                  display="flex"
                                  alignItems={'center'}
                                  cursor="pointer"
                                  onClick={() => {
                                    handleSelectApp(app.tag)
                                    handleProfileAppsSelected(
                                      'SIMPLAZ',
                                      workspace.id,
                                    )
                                  }}
                                >
                                  <Text
                                    fontSize="13px"
                                    fontFamily={'Sora'}
                                    fontWeight={'400'}
                                    color="#666666"
                                    ml="57px"
                                  >
                                    Perfil{' '}
                                    {workspace.name.replace(
                                      'Usuário',
                                      'Operacional',
                                    )}
                                  </Text>
                                </AccordionPanel>
                              ))}
                            </>
                          ))}

                        {app.tag.includes('GE_') && (
                          <>
                            {GestorProfiles.filter(
                              (app) => app.id !== '999',
                            ).map((gestor) => (
                              <AccordionPanel
                                key={gestor.id}
                                p="0"
                                h="37px"
                                borderBottom="1px solid #CBD5E0"
                                bg={
                                  profileApps.some(
                                    (u) =>
                                      u.Aplicativo === 'GESTOR' &&
                                      u.Perfil === gestor.id,
                                  )
                                    ? '#EAF0F9'
                                    : '#FFF'
                                }
                                color={'#303030'}
                                display="flex"
                                alignItems={'center'}
                                cursor="pointer"
                                onClick={() => {
                                  handleSelectApp(app.tag)
                                  handleProfileAppsSelected('GESTOR', gestor.id)
                                }}
                              >
                                <Text
                                  fontSize="13px"
                                  fontFamily={'Sora'}
                                  fontWeight={'400'}
                                  color="#666666"
                                  ml="57px"
                                >
                                  Perfil {gestor.name}
                                </Text>
                              </AccordionPanel>
                            ))}
                          </>
                        )}

                        {app.tag.includes('UN_') && (
                          <>
                            {UniqueProfiles.filter(
                              (app) => app.id !== '999',
                            ).map((unique) => (
                              <AccordionPanel
                                key={unique.id}
                                p="0"
                                h="37px"
                                borderBottom="1px solid #CBD5E0"
                                bg={
                                  profileApps.some(
                                    (u) =>
                                      u.Aplicativo === 'UNIQUE' &&
                                      u.Perfil === unique.id,
                                  )
                                    ? '#EAF0F9'
                                    : '#FFF'
                                }
                                color={'#303030'}
                                display="flex"
                                alignItems={'center'}
                                cursor="pointer"
                                onClick={() => {
                                  handleSelectApp(app.tag)
                                  handleProfileAppsSelected('UNIQUE', unique.id)
                                }}
                              >
                                <Text
                                  fontSize="13px"
                                  fontFamily={'Sora'}
                                  fontWeight={'400'}
                                  color="#666666"
                                  ml="57px"
                                >
                                  Perfil {unique.name}
                                </Text>
                              </AccordionPanel>
                            ))}
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <Flex
                      key={app.tag}
                      h="37px"
                      borderBottom="1px solid #CBD5E0"
                      bg={appsSelected.includes(app.tag) ? '#EAF0F9' : '#FFF'}
                      color={'#303030'}
                      alignItems={'center'}
                      cursor="pointer"
                      onClick={() => {
                        handleSelectApp(app.tag)
                      }}
                    >
                      <GetIconApp appTag={app.tag} />
                      <Text
                        fontSize="14px"
                        fontFamily={'Sora'}
                        fontWeight={'400'}
                        color="#303030"
                      >
                        {app.nome}
                      </Text>
                    </Flex>
                  )}
                </div>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

interface IconsUrlProps {
  gestor: {
    default: string
    hover: string
    favorite: string
  }
  unique: {
    default: string
    hover: string
    favorite: string
  }
  simplaz: {
    default: string
    hover: string
    favorite: string
  }
  importador: {
    default: string
    hover: string
    favorite: string
  }
  analitics: {
    default: string
    hover: string
    favorite: string
  }
  analiticsPro: {
    default: string
    hover: string
    favorite: string
  }
  quod: {
    default: string
    hover: string
    favorite: string
  }
  clube: {
    default: string
    hover: string
    favorite: string
  }
}

export const IconsUrlProduct: IconsUrlProps = {
  gestor: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_gestor_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_gestor_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_gestor_fav.svg',
  },
  unique: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_unique_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_unique_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_unique_fav.svg',
  },
  simplaz: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_simplaz_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_simplaz_simplaz.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_simplaz_fav.svg',
  },
  importador: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_importafacil_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_importafacil_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_importafacil_fav.svg',
  },
  analiticsPro: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analyticspro_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analyticspro_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analyticspro_fav.svg',
  },
  analitics: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analytics_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analytics_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analytics_fav.svg',
  },
  quod: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_quod_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_quod_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_quod_hover.svg',
  },
  clube: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_clubedocontador_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_clubedocontador_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_clubedocontador_hover.svg',
  },
}

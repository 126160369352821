import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  HStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  Check,
  CheckSquare,
  DownloadSimple,
  Warning,
  XCircle,
  XSquare,
} from "phosphor-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as Notfind } from "src/assets/imgs/not-find.svg";
import { useSession } from "src/contexts";
import { apiInstance } from "src/services/api";
import { FileCard } from "../components/FileCard";
import "./../css/sent.css";

interface Company {
  razao: string;
  cnpjCpf: string;
}

interface SentProps {
  step: number;
  visualization: "card" | "list";
  selectedCompany: Company;
  hasGroup: boolean;
}

interface File {
  id: number;
  email: string;
  name: string;
  type: string;
  initial_date: string;
  originalDate: any;
}

interface ValidFilesProps {
  size: number;
  type: string;
  name: string;
}

interface IsValidFileProps {
  isValid: boolean;
  type: "size" | "type" | null;
  ext: string;
}

interface filesToUpload {
  fileObj: {
    name: string;
    file: Blob;
    typeError: string | null;
    ext: string;
  }[];
}

export const Sent = ({
  step,
  visualization,
  selectedCompany,
  hasGroup,
}: SentProps) => {
  const { session } = useSession();
  const toast = useToast();
  const ref = useRef<HTMLInputElement>(null);
  const alertRef = useRef(null);
  const [files, setFiles] = useState<File[]>([]);
  const [fileAlert, setFileAlert] = useState(false);
  const [filesToUpload, setfilesToUpload] = useState<filesToUpload>({
    fileObj: [],
  });
  const [hasErrorPreview, sethasErrorPreview] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(true);
  const [processing, setProcessing] = useState<
    "pending" | "processing" | "finished"
  >("pending");
  const [processingError, setProcessingError] = useState<string[]>([]);
  const [countFiles, setCountFiles] = useState({error: 0, success: 0});
  const LimitToUploads = 15

  const formatterNameFile = (fileName: string): string => {
    const sepFileName: string[] = fileName.split(".");
    const extension = sepFileName[sepFileName.length - 1];
    const regexName = sepFileName[0].replace(/[^a-z0-9]/gi, "");
    const fixedChar = regexName.substring(0, 50);
    const newFileName = `${fixedChar}.${extension}`;

    return newFileName;
  };

  /**
   *
   * @param  grava todos os arquivos selecionados em um estado (filesToUpload)
  */
  const setUploadFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files;

    if (files && files.length > 0) {
      for (const file of Array.from(files)) {
        try {
          const testFile = await validFile(file);
          setfilesToUpload((prevState: { fileObj: any[] }) => {
            const newIndex = prevState.fileObj.length;
            if (newIndex < LimitToUploads) {
              return {
                fileObj: [
                  ...prevState.fileObj,
                  {
                    index: newIndex,
                    name: file.name,
                    file: file,
                    typeError: testFile.isValidFile.type,
                    ext: testFile.isValidFile.ext,
                  },
                ],
              };
            } else {
              return prevState;
            }
          });
        } catch (error) {
          console.error("Erro ao validar o arquivo:", error);
        }
      }
      setFileAlert(true);
    } else {
      toast({
        title: "Aviso!",
        description: "Nehum arquivo selecionado!",
        position: "top",
        duration: 3000,
        status: "warning",
        isClosable: true,
      });
      clearStates();
    }
  };

  const validFile = async (file: ValidFilesProps) => {
    let isValidFile: IsValidFileProps = { isValid: true, type: null, ext: "" };

    if (file?.size > 3000000) {
      return {
        isValidFile: { isValid: false, type: "size", ext: "" },
        arquivo: null,
      };
    }

    const allowedTypes = ["xml", "ret", "plain", "x-pkcs12", "png", "pdf"];
    const fileType = file?.type.toLowerCase().split("/")[1];
    const isValidType =
      allowedTypes.includes(fileType) ||
      file.name.toLowerCase().includes("rem") ||
      file.name.toLowerCase().includes("ret");

    isValidFile.ext = file.type;

    if (!isValidType) {
      return {
        isValidFile: { isValid: false, type: "type", ext: file.type },
        arquivo: file,
      };
    } else return { isValidFile, arquivo: file };
  };

  /**
   * processa a fila com arquivos para envio
   */
  const processQueue = async (): Promise<void> => {
    setProcessing("processing");
    const limitFiles = LimitToUploads
    // const countFiles = filesToUpload.fileObj.filter(item => item.typeError === null).length;
    const countFiles = filesToUpload?.fileObj?.length
    const limitForFile = countFiles > limitFiles ? limitFiles : countFiles;

    for (let index = 0; limitForFile ; index++) {
      const file = filesToUpload.fileObj[index];
      if (file.typeError === null) {
        const statusQuery = await uploadFiles(file);
        if (statusQuery.status === 500) {
          setProcessingError((prev) => [...prev, file.name]);
        }
        if (index === filesToUpload.fileObj.length - 1) {
          await getSentFiles();
          setProcessing("finished");
        }
      } else {
        console.log(`Arquivo invalido para processamento: ${file.name}`);
      }
    }
  };

  /**
   * 
   * @param objFile envia arquivos para a api
   * @returns 
   */
  const uploadFiles = async (objFile: any) => {
    
    try {
      const convertedFile: string = await convertFileTo64(objFile.file as Blob);
      const payload = {
        cnpj: selectedCompany.cnpjCpf,
        login: session.email,
        nome: formatterNameFile(objFile.name),
        value: convertedFile.toString().split(",")[1],
        extensao: objFile.ext,
      };
      await apiInstance(8010).post(`/Arquivos`, payload, {
        headers: {
          companyId: session.company.id.toString(),
          authorization: `bearer ${session.token}`,
        },
      });

      return { status: 200, msg: "success", fileName: objFile.name };
    } catch (error) {
      return { status: 500, msg: error, fileName: objFile.name };
    } 
  };

  const convertFileTo64 = async (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          const base64String = (reader.result as string);
          resolve(base64String)
        } else {
          reject(new Error("File reading failed"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const setReuploadFiles = () => {
    clearStates();
    ref.current?.click();
  };

  const downloadFile = async (id: number) => {
    try {
      const { data } = await apiInstance(8010).get(`/Arquivos/Content/${id}`, {
        headers: {
          companyId: session.company.id.toString(),
          authorization: `bearer ${session.token}`,
        },
      });
      const isB64 = data.value.includes("base64")
        ? data.value
        : `data:${data.extensao};base64,${data.value}`;

      const downloadLink = document.createElement("a");
      downloadLink.href = isB64;
      downloadLink.download = data.nome;
      downloadLink.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getSentFiles = useCallback(async () => {
    try {
      setIsFetching(true);
      const { data } = await apiInstance(8010).get<any[]>(
        `/Arquivos/${selectedCompany.cnpjCpf}`,
        {
          headers: {
            companyId: session.company.id.toString(),
            authorization: `bearer ${session.token}`,
          },
        }
      );
      
      const formatedfiles = data
        .map((file: any) => {
          let extension = "";
          switch (file.extensao.toLowerCase()) {
            case "application/doc":
              extension = "doc";
              break;
            case "application/pdf":
              extension = "pdf";
              break;
            case "text/plain":
              extension = "txt";
              break;
            case "application/excel":
              extension = "xls";
              break;
            case "application/vnd.ms-excel":
              extension = "xls";
              break;
            case "text/xml":
              extension = "xml";
              break;
            case "application/x-pkcs12":
              extension = "pfx";
              break;
            case "image/jpeg":
              extension = "jpeg";
              break;
            case "image/jpg":
              extension = "jpg";
              break;
            case "image/png":
              extension = "png";
              break;
            default:
              extension = "outros";
              break;
          }

          if (file.nome.toLowerCase().includes("ret")) {
            extension = "ret";
          }
          if (file.nome.toLowerCase().includes("rem")) {
            extension = "rem";
          }

          const initialD = dayjs(new Date(file.dataInserido)).format(
            "DD/MM/YYYY HH:mm:ss"
          );

          return {
            id: file.id,
            email: file.login,
            name: file.nome,
            type: extension,
            initial_date: initialD,
            originalDate: file.dataInserido,
          };
        })
        .sort((a, b) => {
          const da = +new Date(a.originalDate);
          const db = +new Date(b.originalDate);

          return db - da;
        });

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setFiles(() => formatedfiles);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  }, [selectedCompany.cnpjCpf, session.company.id, session.token]);

  const clearStates = () => {
    setFileAlert(false);
    setfilesToUpload({ fileObj: [] });
    setProcessing("pending");
    setProcessingError([]);
    if (ref.current) {
      ref.current.value = "";
    }
  };

  useEffect(() => {
    if (step === 1) getSentFiles();
  }, [getSentFiles, step]);

  useEffect(() => {   

    sethasErrorPreview(filesToUpload.fileObj.some((file) => file.typeError !== null));
    const success = filesToUpload.fileObj.filter(item => item.typeError === null).length;
    setCountFiles({
      success: success,
      error: filesToUpload.fileObj.length - success
    })
  }, [filesToUpload]);

  useEffect(() => {
    setFileAlert(processing !== "processing" && processingError.length === 0 ? false : true);
    if(processing === "finished" && processingError.length === 0) {
      clearStates();
    } 
  }, [processing, processingError]);

  return (
    <>
      <Flex w="full" h="full" flexDir="column">
        <Flex px="4" align="center">
          <input
            ref={ref}
            type="file"
            multiple
            id="file"
            style={{ display: "none" }}
            onChange={setUploadFiles}
            accept=".xml,.ret,.txt,.pfx,.png,.pdf"
          />
          <Button onClick={() => ref.current?.click()} colorScheme="blue">
            Carregar arquivo
          </Button>
          <Flex p="5" alignItems="center" mb={1}>
            <span
              style={{
                height: "8px",
                width: "8px",
                borderRadius: "50%",
                backgroundColor: "#1155BB",
                marginRight: "12px",
              }}
            />
            <Text color="low.medium">
              Formatos suportado: .xml; .ret; .txt; .pfx; .png; .pdf; | Tamanho maximo: 3MB | Limite de 15 arquivos por envio
            </Text>
          </Flex>
        </Flex>

        {isFetching ? (
          <Center h="full" w="full">
            <Spinner
              size="xl"
              color="primary.pure"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </Center>
        ) : !isFetching && files?.length === 0 ? (
          <Center h="full" flexDir="column">
            <Notfind />
            <Text fontSize="20px" fontWeight="bold" color="low.light" mt="18px">
              Nenhum resultado encontrado.
            </Text>
          </Center>
        ) : (
          <>
            {visualization === "list" && (
              <Box mt="12px" overflowY="auto">
                <Table size="sm">
                  <Thead position="sticky" top="0" bg="high.pure">
                    <Tr h="40px">
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="100px"
                      >
                        Formato
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="200px"
                      >
                        Gerado em:
                      </Th>

                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                      >
                        Nome do arquivo
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="60px"
                      />
                    </Tr>
                  </Thead>

                  <Tbody>
                    {files?.map((file) => (
                      <Tr key={file.id}>
                        <Td fontWeight="bold" textTransform="uppercase">
                          .{file.type}
                        </Td>
                        <Td>{file.initial_date}</Td>
                        <Td wordBreak="break-all">{file.name}</Td>
                        <Td py="4px">
                          <IconButton
                            size="sm"
                            variant="ghost"
                            aria-label="visualization"
                            icon={<DownloadSimple size={22} />}
                            position="initial"
                            onClick={() => downloadFile(file.id)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
            {visualization === "card" && (
              <Box overflowY="auto" mt="12px">
                <Grid
                  templateColumns="repeat(auto-fill, minmax(276px, auto))"
                  rowGap="12px"
                  columnGap="24px"
                  padding="6px 10px 10px 6px"
                >
                  {files?.map((file) => (
                    <GridItem key={file.id}>
                      <FileCard
                        file={file}
                        actions={
                          <ButtonGroup size="sm" variant="ghost">
                            <IconButton
                              aria-label="visualization"
                              icon={<DownloadSimple size={22} />}
                              position="initial"
                              onClick={() => downloadFile(file.id)}
                            />
                          </ButtonGroup>
                        }
                      />
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            )}
          </>
        )}
      </Flex>

      {/* UploadFile */}
      <AlertDialog
        isOpen={fileAlert}
        leastDestructiveRef={alertRef}
        onClose={() => clearStates()}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        preserveScrollBarGap
        size="5xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              {processing === "pending" && hasErrorPreview && (
                <>
                  <Box className="iconError">
                    <Warning size={24} color="#004099" weight="bold" />
                  </Box>
                  <Text className="titleMsg">Atenção!</Text>
                  <Text className="subTitleMsg">
                    Os formatos suportados são:
                    <b> .xml, .ret, .txt, .pfx, .png e .pdf.</b>, com até{" "}
                    <b>3MB</b>.
                  </Text>
                  <Text className="subTitleMsg">
                    Limite de {LimitToUploads} arquivos por importação.
                  </Text>
                </>
              )}
              {processing === "pending" && !hasErrorPreview && (
                <>
                  <Box className="iconError">
                    <Check size={28} color="#004099" />
                  </Box>
                  <Text className="titleMsg">
                    Você deseja enviar o(s) arquivo(s)?
                  </Text>
                  <Text className="subTitleMsg">
                    Limite de {LimitToUploads} arquivos por importação.
                  </Text>
                </>
              )}
              {processing === "finished" && processingError.length > 0 && (
                <>
                  <Box className="iconError">
                    <XCircle size={24} color="#ff2930" weight="bold" />
                  </Box>
                  <Text className="titleMsg">
                    Falha ao carregar o(s) arquivo(s).
                  </Text>
                </>
              )}
            </AlertDialogHeader>
            <AlertDialogBody
              className="bodyAlert"
              maxHeight={processing ? "350px" : "250px"}
              minHeight={"200px"}
              overflowY={"auto"}
            >
              {processing === "processing" ? (
                <Box className="processBox">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#1155BB"
                    size="xl"
                  />
                  <Text className="titleMsg">
                    Aguarde, carregando arquivo(s)...
                  </Text>
                </Box>
              ) : (
                <Box className="">
                  {processing === "pending" &&
                    filesToUpload?.fileObj?.map(
                      (item, index) =>
                        index < LimitToUploads && (
                          <Text fontSize={"12px"} key={index}>
                            <>
                              {item.typeError === null ? (
                                <Box className="btnStatusFile">
                                  <CheckSquare
                                    size={16}
                                    color="#387d00"
                                    weight="fill"
                                  />
                                  {item.name}
                                </Box>
                              ) : item.typeError === "size" ? (
                                <Box className="btnStatusFile">
                                  <XSquare
                                    size={16}
                                    color="#b50505"
                                    weight="fill"
                                  />
                                  {" Tamanho excedido"}
                                  {item.name}
                                </Box>
                              ) : (
                                <Box className="btnStatusFile">
                                  <XSquare
                                    size={16}
                                    color="#b50505"
                                    weight="fill"
                                  />
                                  {" Formato inválido"}
                                  {item.name}
                                </Box>
                              )}
                            </>
                          </Text>
                        )
                    )}
                </Box>
              )}
              {processing === "finished" && processingError.length > 0 && (
                <Box>
                  <Text className="subTitleMsg" pb={"28px"}>
                    <b>{processingError.length}</b> arquivo(s) abaixo não foram
                    carregado(s):
                  </Text>
                  {processingError.map((item, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "18px",
                      }}
                    >
                      <XSquare size={16} color="#b50505" weight="fill" />{" "}
                      <Text fontSize={"12px"} key={index}>
                        {item}
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              {(processing === "pending" || processing === "finished") && (
                <>
                  <HStack
                    spacing={4}
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    {processing !== "finished" ? (
                      <Text fontSize={"16px"} color={"#004099"}>
                        Arquivos válidos: {countFiles.success} | Arquivos
                        inválidos: {countFiles.error}
                      </Text>
                    ):(
                      <Text>                        
                      </Text>
                    )}
                    <Box>
                      <Button
                        minWidth="110px"
                        variant="ghost"
                        onClick={() => clearStates()}
                      >
                        Cancelar
                      </Button>
                      {!!filesToUpload.fileObj.some(
                        (file) => file.typeError === null
                      ) && (
                        <Button
                          colorScheme="blue"
                          ml={3}
                          minWidth="110px"
                          onClick={
                            processingError.length > 0
                              ? () => setReuploadFiles()
                              : () => processQueue()
                          }
                        >
                          {processingError.length > 0
                            ? "Tentar novamente"
                            : "Enviar"}
                        </Button>
                      )}
                    </Box>
                  </HStack>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

import { useEffect, useRef, useState } from 'react'
import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { TabHeader } from '../Analytics/components/TabHeader'
import { Helmet } from 'react-helmet-async'
import { useSession } from 'src/contexts'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { ReactComponent as certificate } from 'src/assets/icons/certificate.svg'
import { ReactComponent as pencil } from 'src/assets/icons/note-pencil.svg'
import { ReactComponent as users } from 'src/assets/icons/users_icons.svg'
import { ReactComponent as money } from 'src/assets/icons/currency-circle-dollar.svg'
import { ReactComponent as boobkmarks } from 'src/assets/icons/bookmarks.svg'

const styleTab = {
  default: '#BABABA',
  active: '#1155BB',
  size: '20px',
  weight: 'bold',
}

export const CompanySettings = () => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const isMountedRef = useRef(false)
  const navigate = useNavigate()
  const { session } = useSession()
  const sessionBtnAccess = session.btnsMenu

  const tabsInfo = [
    {
      title: 'Geral',
      path: 'geral',
      icon: pencil,
    },
    {
      title: 'Certificate',
      path: 'certificate',
      icon: certificate,
    },
    {
      title: 'User',
      path: 'Users',
      icon: users,
    },
    {
      title: 'Charge',
      path: 'charge',
      icon: money,
    },
    {
      title: 'Plan',
      path: 'plan',
      icon: boobkmarks,
    },
    { title: 'Create', path: 'users/create' },
    { title: 'Editing', path: 'users/editing' },
  ]

  const clickTab = (index: number) => {
    const { path } = tabsInfo[index]
    navigate(path)
    setTabIndex(index)
  }

  const checkAcessbtn = (nameMenu: string) => {
    return !!sessionBtnAccess.find(
      (item: { btn: string }) => item.btn === nameMenu,
    )
  }

  useEffect(() => {
    isMountedRef.current = true

    const pathSelected = window.location.pathname.split('/')
    const tabSelected = pathSelected[2]
    let tabIndex = 0

    if (tabSelected == 'users') {
      const subDir = pathSelected[3] ? pathSelected[3] : ''
      tabIndex = tabsInfo.findIndex((tab) => tab.path === `users/${subDir}`)
      tabIndex = tabIndex !== -1 ? 2 : 0
      setTabIndex(tabIndex)
    } else {
      tabIndex = tabsInfo.findIndex((tab) => tab.path === tabSelected)
    }
    if (tabIndex !== -1 && tabSelected !== 'users' && isMountedRef.current) {
      clickTab(tabIndex)
    }
    return () => {
      isMountedRef.current = false
    }
  }, [window?.location?.pathname])

  return (
    <>
      <Helmet>
        <title>Configurações | Workspace</title>
      </Helmet>
      <Tabs
        index={tabIndex}
        isLazy
        w="full"
        bg="high.pure"
        borderRadius="4px"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
      >
        <TabListCustom>
          <TabHeader
            title="Dados Cadastrais"
            icon={tabsInfo[0]?.icon}
            hasSelect={tabIndex === 0}
            onClick={() => clickTab(0)}
          />

          <TabHeader
            title="Certificado Digital"
            icon={tabsInfo[1]?.icon}
            hasSelect={tabIndex === 1}
            isDisabled={!checkAcessbtn('admin')}
            onClick={() => clickTab(1)}
          />

          <TabHeader
            title="Usuários"
            icon={tabsInfo[2]?.icon}
            hasSelect={tabIndex === 2}
            onClick={() => clickTab(2)}
          />

          <TabHeader
            title="Pagamentos"
            icon={tabsInfo[3]?.icon}
            hasSelect={tabIndex === 3}
            isDisabled={!checkAcessbtn('admin')}
            onClick={() => clickTab(3)}
          />

          <TabHeader
            title="Meus Serviços"
            icon={tabsInfo[4]?.icon}
            hasSelect={tabIndex === 4}
            onClick={() => clickTab(4)}
          />
        </TabListCustom>

        <TabPanels>
          <TabPanel id="geral" h="calc(100vh - 130px)" overflowY="auto">
            <Outlet />
          </TabPanel>

          <TabPanel id="certificado" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>

          <TabPanel id="users" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>

          <TabPanel id="plano" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>

          <TabPanel id="charge" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

const TabListCustom = styled(TabList)`
  .chakra-text {
    font-size: 14px;
    font-weight: 500;
  }
`

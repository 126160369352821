import { formatting } from "src/utilities/formattingToPattern";
import { Flex, Text } from "@chakra-ui/react";
import { dataOrcamentoInterface } from "./interface";

type clientInterface = Pick<dataOrcamentoInterface, "client">;

export const DataClient = ({ client }: clientInterface) => {
  return (
    <Flex
      maxW={{ xl: "1024px", sm: "full" }}
      w="full"
      mx="auto"
      p={{ "2xl": "0 64px 10px 64px;", sm: "0 24px 10px 24px" }}
      mt="20px"
      borderBottom="1px solid #ccc"
    >
      <Flex flexDir="column" w="full">
        <Text
          fontFamily={"inter"}
          fontWeight={"700"}
          fontSize={"20px"}
          color="#606060"
        >
          Cliente
        </Text>
        <Flex
          w="full"
          fontFamily={"inter"}
          mt="10px"
          gap="35px"
          flexDir={{ xl: "row", md: "row", sm: "column", base: "column" }}
        >
          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Nome do cliente
            </Text>
            <Text color="#606060">{client.name}</Text>
          </Flex>
          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              E-mail
            </Text>
            <Text color="#606060">{client.email ?? ""}</Text>
          </Flex>

          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Contato
            </Text>
            <Text color="#606060">
              {formatting({
                value: client.contact ?? "",
                pattern: "phone",
              })}
            </Text>
          </Flex>
        </Flex>

        <Flex
          w="full"
          fontFamily={"inter"}
          mt="10px"
          gap="35px"
          flexDir={{ xl: "row", md: "row", sm: "column", base: "column" }}
        >
          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Endereço
            </Text>
            <Text color="#606060">{client.clientCostumer[0].address}</Text>
          </Flex>
          <Flex
            flexDir={"column"}
            h="50px"
            w="50%"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Número
            </Text>
            <Text color="#606060">{client.clientCostumer[0].number ?? ""}</Text>
          </Flex>

          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Bairro
            </Text>
            <Text color="#606060">{client.clientCostumer[0].complement}</Text>
          </Flex>

          <Flex
            flexDir={"column"}
            h="50px"
            w="50%"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              CEP
            </Text>
            <Text color="#606060">
              {formatting({
                value: client.clientCostumer[0].zipCode ?? "",
                pattern: "cep",
              })}
            </Text>
          </Flex>
        </Flex>

        <Flex
          w="full"
          fontFamily={"inter"}
          mt="10px"
          gap="35px"
          flexDir={{ xl: "row", md: "row", sm: "column", base: "column" }}
        >
          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              Cidade
            </Text>
            <Text color="#606060">{client.clientCostumer[0].city}</Text>
          </Flex>
          <Flex
            flexDir={"column"}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={"1px solid rgba(96,96,96,.7)"}
          >
            <Text fontSize={"13px"} color="#6747b3">
              UF
            </Text>
            <Text color="#606060">{client.clientCostumer[0].state ?? ""}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

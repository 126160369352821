import { apiInstance } from "src/services/api";
import { Box, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSession } from "src/contexts";


type AccountMlProps = {
  configurationId: number,
  companyId: number,
  userId: number,
  origin: any,
  value: string,
  key: string,
  dateCreated: Date,
  inativo: any,
}

type StateQueryProps = {
  data?:{[key: string]: any}
  status: number | undefined
  msg : string | undefined
}

export const MercadoLivreLink = () => {
  const location = useLocation();
  const { session } = useSession();
  const toast = useToast()

  //http://localhost:3000/store/mercadolivrelink?code=TG-667dbc0741caee0001c5b635-114873496

  const exitPage = () => {
    setTimeout(() => {
      window.close() 
    },2500)
  }

  useEffect(() => {
    const fetchData = async () => {
      
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");

      const getAccessToken = async (cnpj: string) => {
        const {
          data: { Token },
        } = await apiInstance(
          8006,
          "https://api.serverlondrisoft.com:8006"
        ).get(`/Company/getTokenByCnpjCpf/${cnpj}`, {
          headers: {
            Authorization: "Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb",
          },
        });
        return Token;
      };

      const getAccountMl = async (token: string) => {
        try {
          const { data } = await apiInstance(
            8006,
            "https://api.serverlondrisoft.com:8006"
          ).get(`/Configuration/CompanyId/${session?.company?.id}`, {
            headers: {
              GatewayLS: "2e44bb6339e6aacd8faeca8fd4e8694e",
              Authorization: token,
            },
          });
          const mercadoLivreConfig = data.find(
            (config: { key: string }) => config.key === "mercado-livre-token"
          );
          return {
            data: mercadoLivreConfig,
            status: 200,
            msg: "Conta do Mercado Livre obtida com sucesso",
          };
        } catch (error) {
          return { status: 500, msg: "Erro ao obter conta do Mercado Livre" };
        }
      };

      const insertAccountMl = async (
        token: string,
        code: string
      ) => {

        const payload = {
          CompanyId: session?.company?.id,
          UserId: session?.id,
          Origin: "",
          Value: code,
          Key: "mercado-livre-token",
        };

        try {
            await apiInstance(
              8006,
              "https://api.serverlondrisoft.com:8006"
            ).post(
              '/Configuration',
              payload,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
          return {
            status: 200,
            msg: "Conta do Mercado Livre inserida com sucesso",
          };       
        } catch (error) {
          return {
            status: 500,
            msg: "Erro ao inserir conta do Mercado Livre",
          };
        }
      };

      const updateAccountMl = async (
        token: any,
        accountMl: AccountMlProps,
        code: string
      ) => {

        const payload = {
          configurationId: accountMl?.configurationId,
          companyId: accountMl?.companyId,
          userId: accountMl?.userId,
          origin: null,
          value: code,
          key: accountMl?.key,
          dateCreated: new Date().toISOString(),
          inativo: 0,
        };


        try {
          await apiInstance(
            8006,
            "https://api.serverlondrisoft.com:8006"
          ).put(
            `/Configuration/${accountMl?.configurationId}`,
            payload,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          return {
            status: 200,
            msg: "Conta do Mercado Livre atualizada com sucesso",
          };
        } catch (error) {
          return {
            status: 500,
            msg: "Erro ao atualizar conta do Mercado Livre",
          };
        }
      };      
      
      if (code) {
        const token = await getAccessToken(session?.company?.document);
        let resultQuery: StateQueryProps | null = null;
        console.log('query',resultQuery)
        if (token) {
          const hasAccountMl = await getAccountMl(token);
console.log('hasAccountMl: ', hasAccountMl);

          if (hasAccountMl.data) {
            // resultQuery = await updateAccountMl(
            //   token,
            //   hasAccountMl?.data,
            //   code as string
            // );
            // setTimeout(() => {
            //   if (resultQuery?.status === 200) {
            //     toast({
            //       title: "Conta Vinculada com sucesso",
            //       status: "success",
            //       duration: 2000,
            //       isClosable: true,
            //     });
            //   } else {
            //     toast({
            //       title: resultQuery?.msg as string,
            //       status: "error",
            //       duration: 2000,
            //       isClosable: true,
            //     });
            //   }
            //   exitPage();           
            // }, 3000);
          } else {
            resultQuery = await insertAccountMl(token, code);
            setTimeout(() => {
              if (resultQuery?.status === 200) {
                toast({
                  title: "Conta Vinculada com sucesso",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              } else {
                toast({
                  title: resultQuery?.msg as string,
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
              exitPage();
            }, 3000);
          }
        } else {
          setTimeout(() => {
            toast({
              title: "Erro ao obter token",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            exitPage();
          }, 3000);
        }
      }else{
       setTimeout(() => {
          toast({
            title: 'Erro ao vincular conta',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          exitPage();
       }, 3000);         
      }
      
    };
    fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      h="calc(100vh - 110px)"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={
          "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/mercado-livre_loading2.gif"
        }
        alt="loading"
      />
    </Box>
  );
};

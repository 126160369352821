/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  CaretLeft,
  CaretRight,
  FolderOpen,
  HouseSimple,
  NotePencil,
  Stack,
  Storefront,
  UserCircle,
  Users,
  UsersThree,
  Certificate,
  CurrencyCircleDollar,
  Bookmarks,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import menuRoutes from "./hooks/menuRoutes";
import pack from "../../../../package.json";
import { ButtonSubMenu } from "./components/ButtonSubmenu";
import { ButtonCompany } from "./components/ButtonCompany";
import { useSession } from "src/contexts";
import { ReactComponent as Logo } from "src/assets/imgs/workspace_horizontal.svg";
import { ReactComponent as LogoCompact } from "src/assets/imgs/workspace-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import "./css/index.css";
import { ItemsSubmenuProduct } from "./components/ItemsSubmenuProduct";
import CopyToClipboard from "react-copy-to-clipboard";
import { useGetUser } from "./services/useGetUser";
import ButtonsFavorite from "./components/ButtonsFavorite";
import {
  ArrSubMenuProdProps,
  FavoriteObj,
  IsHoverIconProps,
  ItemsMenuProps,
  ItensMenuProps,
  ObjectMenuProps,
  OpenMenuProps,
} from "./types/types";
import { useUpdateUser } from "./services/useUpdateUser";
import { decrypted } from "src/utilities/hashing";

const defaultIcon = {
  width: 24,
  height: 24,
};

const styleIcon = {
  color: "#666666",
  ...defaultIcon,
};

const styleIconOpen = {
  color: "#004099",
  ...defaultIcon,
};

const stylesIconAccount = {
  color: "#1155BB",
  ...defaultIcon,
};

const stylesIconAccountHover = {
  color: "#006AFF",
  ...defaultIcon,
};

export const SideMenu = ({ isFullPage, isMediumPage }: any) => {
  const [isOpen, setIsOpen] = useState<OpenMenuProps>({
    isOpenProduct: false,
    isOpenAccount: false,
    isOpenPopOver: { name: null, state: false },
  });
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [isHoverIcon, setIsHoverIcon] = useState<IsHoverIconProps>({
    icon: null,
    state: false,
  });
  const [arrSubMenuProd, setArrSubMenuProd] = useState<ArrSubMenuProdProps[]>(
    []
  );
  const [btnsFavorite, setBtnsFavorite] = useState<any>();
  const { dataUser } = useGetUser();
  const { favoriteUpdated, updateFavorite } = useUpdateUser();
  const { session } = useSession();
  const sessionBtnAccess = session.btnsMenu;
  const navigate = useNavigate();
  const toast = useToast();
  const menuProdLgRef = useRef<HTMLLIElement>(null);
  const menuProdMdRef = useRef<HTMLLIElement>(null);
  const menuAccountLgRef = useRef<HTMLLIElement>(null);
  const menuAccountMdRef = useRef<HTMLLIElement>(null);
  const isMountedRef = useRef(false);
  const selectedMenu = (nameMenu: string) => {
    setActiveMenu(nameMenu);
    setIsOpen((prevState) => ({
      isOpenProduct:
        nameMenu === itemsMenu?.product?.name
          ? !prevState.isOpenProduct
          : false,
      isOpenAccount:
        nameMenu === itemsMenu?.account?.name
          ? !prevState.isOpenAccount
          : false,
      isOpenPopOver: {
        name: nameMenu,
        state:
          (nameMenu === itemsMenu?.product?.name && !prevState.isOpenProduct) ||
          (nameMenu === itemsMenu?.account?.name && !prevState.isOpenAccount),
      },
    }));

    redirectMenuProduct(nameMenu);
  };

  const itemsMenu: Record<string, ItemsMenuProps> = {
    init: {
      name: menuRoutes?.initial?.name,
      label: "Início",
      icon: (
        <HouseSimple
          style={
            activeMenu === menuRoutes?.initial?.name ? styleIconOpen : styleIcon
          }
          weight="bold"
        />
      ),
      action: () => selectedMenu(menuRoutes?.initial?.name),
    },
    product: {
      name: menuRoutes?.product?.name,
      label: "Produtos",
      icon: (
        <Stack
          style={isOpen.isOpenProduct ? styleIconOpen : styleIcon}
          weight="bold"
        />
      ),
      action: () => selectedMenu(menuRoutes?.product?.name),
    },
    file: {
      name: menuRoutes?.file?.name,
      label: "Arquivos",
      icon: (
        <FolderOpen
          style={
            activeMenu === menuRoutes?.file?.name ? styleIconOpen : styleIcon
          }
          weight="bold"
        />
      ),
      action: () => selectedMenu(menuRoutes?.file?.name),
    },
    client: {
      name: menuRoutes?.client?.name,
      label: "Meus Clientes",
      icon: (
        <UsersThree
          style={
            activeMenu === menuRoutes?.client?.name ? styleIconOpen : styleIcon
          }
          weight="bold"
        />
      ),
      action: () => selectedMenu(menuRoutes?.client?.name),
    },
    app: {
      name: menuRoutes?.app?.name,
      label: "Loja de Apps",
      icon: (
        <Storefront
          style={
            activeMenu === menuRoutes?.app?.name ? styleIconOpen : styleIcon
          }
          weight="bold"
        />
      ),
      action: () => selectedMenu(menuRoutes?.app?.name),
    },
    account: {
      name: menuRoutes?.account?.name,
      label: "Minha Conta",
      icon: (
        <UserCircle
          style={isOpen.isOpenAccount ? styleIconOpen : styleIcon}
          weight="bold"
        />
      ),
      action: () => selectedMenu(menuRoutes?.account?.name),
    },
  };

  const ItemsSubmenuProductFunction = ItemsSubmenuProduct();

  const itensSubMenuAccount = (): ItensMenuProps => {
    const itensMenu = {
      data: {
        label: "Dados Cadastrais",
        name: menuRoutes?.dataUser?.name,
        icon: (
          <NotePencil
            style={
              isHoverIcon?.icon === menuRoutes?.dataUser?.name &&
              isHoverIcon?.state
                ? stylesIconAccountHover
                : stylesIconAccount
            }
            weight="regular"
          />
        ),
      },
      certificate: {
        label: "Certificado Digital",
        name: menuRoutes?.certificate?.name,
        icon: (
          <Certificate
            style={
              isHoverIcon?.icon === menuRoutes?.certificate?.name &&
              isHoverIcon?.state
                ? stylesIconAccountHover
                : stylesIconAccount
            }
            weight="regular"
          />
        ),
      },
      user: {
        label: "Usuários",
        name: menuRoutes?.user?.name,
        icon: (
          <Users
            style={
              isHoverIcon?.icon === menuRoutes?.user?.name && isHoverIcon?.state
                ? stylesIconAccountHover
                : stylesIconAccount
            }
            weight="regular"
          />
        ),
      },
      payment: {
        label: "Pagamentos",
        name: menuRoutes?.payment?.name,
        icon: (
          <CurrencyCircleDollar
            style={
              isHoverIcon?.icon === menuRoutes?.payment?.name &&
              isHoverIcon?.state
                ? stylesIconAccountHover
                : stylesIconAccount
            }
            weight="regular"
          />
        ),
      },
      service: {
        label: "Meus Serviços",
        name: menuRoutes?.service?.name,
        icon: (
          <Bookmarks
            style={
              isHoverIcon?.icon === menuRoutes?.service?.name &&
              isHoverIcon?.state
                ? stylesIconAccountHover
                : stylesIconAccount
            }
            weight="regular"
          />
        ),
      },
    };
    return itensMenu;
  };

  const redirectMenuProduct = (nameMenu: string) => {
    switch (nameMenu) {
      case itemsMenu?.init?.name:
        navigate(menuRoutes?.initial?.path);
        break;

      case itemsMenu?.file?.name:
        navigate(menuRoutes?.file?.path);
        break;

      case itemsMenu?.client?.name:
        navigate(menuRoutes?.client?.path);
        break;

      case itemsMenu?.app?.name:
        navigate(menuRoutes?.app?.path);
        break;

      case menuRoutes?.dataUser?.name:
        navigate(menuRoutes?.dataUser?.path);
        break;

      default:
        break;
    }
  };

  const redirectSubmenuAccount = (nameSubmenu: string) => {
    switch (nameSubmenu) {
      case menuRoutes?.dataUser?.name:
        navigate(menuRoutes?.dataUser?.path);
        break;

      case menuRoutes?.certificate?.name:
        navigate(menuRoutes?.certificate?.path);
        break;

      case menuRoutes?.user?.name:
        navigate(menuRoutes?.user?.path);
        break;

      case menuRoutes?.payment?.name:
        navigate(menuRoutes?.payment?.path);
        break;

      case menuRoutes?.service?.name:
        navigate(menuRoutes?.service?.path);
        break;

      default:
        break;
    }
  };

  const returnBtnMenu = (nameMenu: string) => {
    return !!sessionBtnAccess.find(
      (item: { btn: string }) => item.btn === nameMenu
    );
  };

  const changeIcon = ({ icon, state }: IsHoverIconProps) => {
    setIsHoverIcon({ icon, state });
  };

  const returnBtnSubmenuAccount = (item: ObjectMenuProps, index?: number) => {
    let testBtn = true;
    if (item.name === menuRoutes?.certificate?.name) {
      testBtn = !!sessionBtnAccess?.find(
        (item: { btn: any; name: string }) => item.btn === "certificate"
      );
    }

    if (item.name === menuRoutes?.payment?.name) {
      testBtn = !!sessionBtnAccess?.find(
        (item: { btn: any; name: string }) => item.btn === "payment"
      );
    }

    if (testBtn) {
      return (
        <Button
          key={index}
          variant="unstyled"
          width={"100%"}
          sx={{
            "&:focus": {
              boxShadow: "none",
              outline: "none",
            },
          }}
          onClick={() => redirectSubmenuAccount(item?.name)}
        >
          <Flex
            className={"textSubMenuAccount"}
            onMouseEnter={() =>
              changeIcon({
                icon: item.name,
                state: true,
              })
            }
            onMouseLeave={() =>
              changeIcon({
                icon: item.name,
                state: false,
              })
            }
            _hover={{ backgroundColor: "#002A66" }}
          >
            <Center w="30px">
              <Text pl={"px"}>{item.icon}</Text>
            </Center>
            <Center flex="1" justifyContent={"flex-start"}>
              <Text
                fontFamily={"title"}
                fontSize={"14px"}
                fontWeight={"normal"}
                pl={"6px"}
                color={
                  isHoverIcon?.icon === item?.name && isHoverIcon?.state
                    ? "white"
                    : "low.dark"
                }
              >
                {item.label}
              </Text>
            </Center>
          </Flex>
        </Button>
      );
    }
  };

  const changeFavorite = (
    e: { stopPropagation: () => void },
    nomeApp: string
  ) => {
    e.stopPropagation();
    setBtnsFavorite((prevState: FavoriteObj) => {
      const newFavorites = {
        ...prevState,
        tag: {
          ...prevState.tag,
          [nomeApp]: !btnsFavorite?.tag[nomeApp] || false,
        },
      };
      /**
       * api atualizacao favoritos
       */
      updateFavorite(newFavorites, dataUser?.user);
      return newFavorites;
    });
  };

  const returnStorageFavorites = (favorites?: FavoriteObj) => {
    if (favorites) {
      localStorage.setItem("@btnsFavorites", JSON.stringify(favorites));
    }
    return JSON.parse(localStorage.getItem("@btnsFavorites") || "{}");
  };

  useEffect(() => {
    isMountedRef.current = true;
    setArrSubMenuProd([]);
    const returnBtnSubMenuProd = () => {
     
      if (sessionBtnAccess.length > 0) {
        ItemsSubmenuProductFunction.product
          .filter((item) =>
            sessionBtnAccess?.some(
              (btn: { btn: string }) => btn.btn === item.id
            )
          )         
          .map((item) => {
            setArrSubMenuProd((prev: any[]) => [...prev, item]);
          });
      }
      return null;
    };

    if (isMountedRef.current) {
      returnBtnSubMenuProd();
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [sessionBtnAccess]);

  useEffect(() => {
    setBtnsFavorite(returnStorageFavorites(dataUser?.favorites));
  }, [dataUser]);

  useEffect(() => {
    /**atualiza localstorage */
    if (favoriteUpdated && Object.keys(favoriteUpdated).length > 0) {
      returnStorageFavorites(favoriteUpdated);
    }
  }, [favoriteUpdated]);

  useEffect(() => {
    /**
     *
     * @param event captura evento de clique fora do menu, fechar submenus
     */
    const clickLayout = (event: { target: any }) => {
      
      if (
        (menuProdMdRef.current &&
          isMediumPage &&
          isMediumPage &&
          !menuProdMdRef.current.contains(event.target as Node)) ||
        (menuProdLgRef.current &&
          isFullPage &&
          !menuProdLgRef.current.contains(event.target as Node))
      ) {
        if (
          isOpen?.isOpenPopOver?.state === true &&
          isOpen?.isOpenPopOver?.name === itemsMenu?.product?.name
        ) {
          selectedMenu(menuRoutes?.product?.name);
        }
      }
      if (
        (menuAccountMdRef.current &&
          isMediumPage &&
          isMediumPage &&
          !menuAccountMdRef.current.contains(event.target as Node)) ||
        (menuAccountLgRef.current &&
          isFullPage &&
          !menuAccountLgRef.current.contains(event.target as Node))
      ) {
        if (
          isOpen?.isOpenPopOver?.state === true &&
          isOpen?.isOpenPopOver?.name === itemsMenu?.account?.name
        ) {
          selectedMenu(menuRoutes?.account?.name);
        }
      }
    };

    document.addEventListener("mousedown", clickLayout);
    
    return () => {
      document.removeEventListener("mousedown", clickLayout);
    };
  }, [isOpen?.isOpenPopOver?.state]);

  return (
    <>
      {isFullPage && (
        <Box className="menu">
          <Flex flexDir="column" className="container">
            <Box className="header">
              <Link to={"/initial"}>
                <Flex pb={"2px"}>
                  <Logo width={172} height={"100%"} />
                </Flex>
              </Link>
              <Box pt={"26px"}>
                <ButtonCompany />
              </Box>
              <Divider borderColor={"#CCE1FF"} pt={"5px"} />
            </Box>
            <Box className="containerApps">
              <ButtonsFavorite
                btnsFavorite={btnsFavorite}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                isFullPage={isFullPage}
              ></ButtonsFavorite>
              <Box className="body">
                <List className="listMenu">
                  {/* inicio */}
                  <ListItem
                    fontFamily={"inter"}
                    className={`listMenuItem ${
                      activeMenu === itemsMenu?.init?.name ? "activeMenu" : ""
                    }`}
                    onClick={itemsMenu?.init?.action}
                  >
                    <Box className="listmenuItemBox">
                      <Box className="text">
                        {itemsMenu?.init?.icon}
                        <Text>{itemsMenu.init.label}</Text>
                      </Box>
                    </Box>
                  </ListItem>
                  {/* produtos */}
                  <ListItem
                    ref={menuProdLgRef}
                    fontFamily={"inter"}
                    className={`listMenuItemSub ${
                      isOpen?.isOpenProduct
                        ? "activeMenu listMenuItemSubActive"
                        : ""
                    }`}
                    onClick={itemsMenu?.product?.action}
                    id="btnProduct"
                  >
                    <Popover
                      id="popover-menu"
                      placement="right-start"
                      gutter={0}
                      trigger="click"
                      isOpen={
                        isOpen?.isOpenPopOver?.name ===
                          itemsMenu?.product?.name &&
                        isOpen?.isOpenPopOver?.state
                      }
                    >
                      <PopoverTrigger>
                        <Box className="listmenuItemBox">
                          <Box className="text">
                            {itemsMenu?.product?.icon}
                            <Text>{itemsMenu.product.label}</Text>
                          </Box>
                          <Box
                            className={
                              isOpen?.isOpenProduct ? "iconActive" : "icon"
                            }
                          >
                            {isOpen?.isOpenProduct ? (
                              <CaretLeft
                                size={16}
                                color="#004099"
                                weight="bold"
                              />
                            ) : (
                              <CaretRight
                                size={16}
                                color="#666666"
                                weight="bold"
                              />
                            )}
                          </Box>
                        </Box>
                      </PopoverTrigger>
                      <CustomPopoverContent>
                        <PopoverBody sx={{ padding: "0px" }}>
                          {isOpen?.isOpenPopOver?.name ===
                            itemsMenu?.product?.name &&
                            isOpen?.isOpenPopOver?.state &&
                            arrSubMenuProd.map((item: any) => (
                              <CustomBoxSub
                                className="customBox"
                                key={item.label}
                              >
                                <ButtonSubMenu
                                  id={item.id}
                                  label={item.label}
                                  iconDefault={item.iconDefault}
                                  iconHover={item.iconHover}
                                  beta={false}
                                  action={item.action}
                                  changeFavorite={changeFavorite}
                                  favorite={
                                    btnsFavorite?.tag?.[item?.id] || false
                                  }
                                />
                              </CustomBoxSub>
                            ))}
                        </PopoverBody>
                      </CustomPopoverContent>
                    </Popover>
                  </ListItem>
                  {/* arquivos */}
                  {sessionBtnAccess &&
                    sessionBtnAccess.length > 0 &&
                    returnBtnMenu(itemsMenu?.file?.name) && (
                      <ListItem
                        fontFamily={"inter"}
                        className={`listMenuItem ${
                          activeMenu === itemsMenu?.file?.name
                            ? "activeMenu"
                            : ""
                        }`}
                        onClick={itemsMenu?.file?.action}
                      >
                        <Box className="listmenuItemBox">
                          <Box className="text">
                            {itemsMenu?.file?.icon}
                            <Text>{itemsMenu.file.label}</Text>
                          </Box>
                        </Box>
                      </ListItem>
                    )}
                  {/* clientes */}
                  {sessionBtnAccess &&
                    sessionBtnAccess.length > 0 &&
                    returnBtnMenu(itemsMenu?.client?.name) && (
                      <ListItem
                        fontFamily={"inter"}
                        className={`listMenuItem ${
                          activeMenu === itemsMenu?.client?.name
                            ? "activeMenu"
                            : ""
                        }`}
                        onClick={itemsMenu?.client?.action}
                      >
                        <Box className="listmenuItemBox">
                          <Box className="text">
                            {itemsMenu?.client?.icon}
                            <Text>{itemsMenu.client.label}</Text>
                          </Box>
                        </Box>
                      </ListItem>
                    )}
                  {/* apps */}
                  <ListItem
                    fontFamily={"inter"}
                    className={`listMenuItem ${
                      activeMenu === itemsMenu?.app?.name ? "activeMenu" : ""
                    }`}
                    onClick={itemsMenu?.app?.action}
                  >
                    <Box className="listmenuItemBox">
                      <Box className="text">
                        {itemsMenu?.app?.icon}
                        <Text>{itemsMenu.app.label}</Text>
                      </Box>
                    </Box>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Flex>
          <Box className="footer">
            <Box className="account">
              {/* minha conta */}
              <List className="listMenu">
                <ListItem
                  ref={menuAccountLgRef}
                  fontFamily={"inter"}
                  className={`listMenuItemSub ${
                    isOpen?.isOpenAccount
                      ? "activeMenu listMenuItemSubActive"
                      : ""
                  }`}
                  onClick={itemsMenu?.account?.action}
                >
                  <Popover
                    placement="right-end"
                    gutter={0}
                    trigger="click"
                    isOpen={
                      isOpen?.isOpenPopOver?.name ===
                        itemsMenu?.account?.name && isOpen?.isOpenPopOver?.state
                    }
                  >
                    <PopoverTrigger>
                      <Box className="listmenuItemBox">
                        <Box className="text">
                          {itemsMenu?.account?.icon}
                          <Text>{itemsMenu.account.label}</Text>
                        </Box>
                        <Box
                          className={
                            isOpen?.isOpenAccount ? "iconActive" : "icon"
                          }
                        >
                          {isOpen?.isOpenAccount ? (
                            <CaretLeft
                              size={16}
                              color="#004099"
                              weight="bold"
                            />
                          ) : (
                            <CaretRight
                              size={16}
                              color="#666666"
                              weight="bold"
                            />
                          )}
                        </Box>
                      </Box>
                    </PopoverTrigger>
                    <CustomPopoverContent>
                      <PopoverBody sx={{ padding: "0px" }}>
                        <CustomBoxSub>
                          {/* btns submenu account */}
                          {Object.values(itensSubMenuAccount()).map(
                            (item: ObjectMenuProps, index) =>
                              returnBtnSubmenuAccount(item, index)
                          )}
                        </CustomBoxSub>
                      </PopoverBody>
                    </CustomPopoverContent>
                  </Popover>
                </ListItem>
              </List>
              <Box className="accountVersion">
                <Divider borderColor={"#CCE1FF"} />
                <Text
                  fontSize={"14px"}
                  fontFamily={"title"}
                  fontWeight={"bold"}
                  color={"#A3A3A3"}
                  pt={"8px"}
                >
                  Versão: {pack?.version}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isMediumPage && (
        <Box className="menu">
          <Flex flexDir="column" className="container">
            <Box className="header">
              <Tooltip
                label={`${session.company.name}`}
                bg="gray.400"
                hasArrow
                placement="right"
                isDisabled={isOpen?.isOpenProduct}
              >
                <Link to={"/initial"}>
                  <CopyToClipboard
                    text={session.company.document}
                    onCopy={() => {
                      toast({
                        title: "Cnpj copiado com sucesso",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }}
                  >
                    <Flex>
                      <LogoCompact width={40} />
                    </Flex>
                  </CopyToClipboard>
                </Link>
              </Tooltip>
              <Divider borderColor={"#CCE1FF"} />
            </Box>
            <Box className="containerApps">
              <ButtonsFavorite
                btnsFavorite={btnsFavorite}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                isFullPage={isFullPage}
              ></ButtonsFavorite>
              <Box className="body">
                <List className="listMenu">
                  {/* inicio */}
                  <Tooltip
                    label="Início"
                    bg="gray.400"
                    hasArrow
                    placement="right"
                  >
                    <ListItem
                      fontFamily={"inter"}
                      className={`listMenuItem ${
                        activeMenu === itemsMenu?.init?.name ? "activeMenu" : ""
                      }`}
                      onClick={() => selectedMenu(itemsMenu?.init?.name)}
                    >
                      <Box className="listmenuItemBox">
                        <Box className="text">{itemsMenu?.init?.icon}</Box>
                      </Box>
                    </ListItem>
                  </Tooltip>
                  {/* produtos */}
                  <Tooltip
                    label="Produtos"
                    bg="gray.400"
                    hasArrow
                    placement="right"
                    isDisabled={isOpen?.isOpenProduct}
                  >
                    <ListItem
                      ref={menuProdLgRef}
                      fontFamily={"inter"}
                      className={`listMenuItemSub ${
                        isOpen?.isOpenProduct
                          ? "activeMenu listMenuItemSubActive"
                          : ""
                      }`}
                      onClick={() => selectedMenu(itemsMenu?.product?.name)}
                    >
                      <Popover
                        id="popover-menu"
                        placement="right-start"
                        gutter={0}
                        trigger="click"
                        isOpen={
                          isOpen?.isOpenPopOver?.name ===
                            itemsMenu?.product?.name &&
                          isOpen?.isOpenPopOver?.state
                        }
                      >
                        <PopoverTrigger>
                          <Box className="listmenuItemBox">
                            <Box className="text">
                              {itemsMenu?.product?.icon}
                            </Box>
                            <Box
                              className={
                                isOpen?.isOpenProduct ? "iconActive" : "icon"
                              }
                            >
                              {isOpen?.isOpenProduct ? (
                                <CaretLeft
                                  size={10}
                                  color="#004099"
                                  weight="bold"
                                />
                              ) : (
                                <CaretRight
                                  size={10}
                                  color="#666666"
                                  weight="bold"
                                />
                              )}
                            </Box>
                          </Box>
                        </PopoverTrigger>
                        <CustomPopoverContent>
                          <PopoverBody sx={{ padding: "0px" }}>
                            {isOpen?.isOpenPopOver?.name ===
                              itemsMenu?.product?.name &&
                              isOpen?.isOpenPopOver?.state &&
                              arrSubMenuProd.map((item: any) => (
                                <CustomBoxSub
                                  className="customBox"
                                  key={item.label}
                                >
                                  <ButtonSubMenu
                                    id={item.id}
                                    label={item.label}
                                    iconDefault={item.iconDefault}
                                    iconHover={item.iconHover}
                                    beta={false}
                                    action={item.action}
                                    changeFavorite={changeFavorite}
                                    favorite={
                                      btnsFavorite?.tag?.[item?.id] || false
                                    }
                                  />
                                </CustomBoxSub>
                              ))}
                          </PopoverBody>
                        </CustomPopoverContent>
                      </Popover>
                    </ListItem>
                  </Tooltip>
                  {/* arquivos */}
                  {sessionBtnAccess &&
                    sessionBtnAccess.length > 0 &&
                    returnBtnMenu(itemsMenu?.file?.name) && (
                      <Tooltip
                        label="Arquivos"
                        bg="gray.400"
                        hasArrow
                        placement="right"
                      >
                        <ListItem
                          fontFamily={"inter"}
                          className={`listMenuItem ${
                            activeMenu === itemsMenu?.file?.name
                              ? "activeMenu"
                              : ""
                          }`}
                          onClick={itemsMenu.file?.action}
                        >
                          <Box className="listmenuItemBox">
                            <Box className="text">{itemsMenu?.file?.icon}</Box>
                          </Box>
                        </ListItem>
                      </Tooltip>
                    )}
                  {/* clientes */}
                  {sessionBtnAccess &&
                    sessionBtnAccess.length > 0 &&
                    returnBtnMenu(itemsMenu?.client?.name) && (
                      <Tooltip
                        label="Meus Clientes"
                        bg="gray.400"
                        hasArrow
                        placement="right"
                      >
                        <ListItem
                          fontFamily={"inter"}
                          className={`listMenuItem ${
                            activeMenu === itemsMenu?.client?.name
                              ? "activeMenu"
                              : ""
                          }`}
                          onClick={itemsMenu.client?.action}
                        >
                          <Box className="listmenuItemBox">
                            <Box className="text">
                              {itemsMenu?.client?.icon}
                            </Box>
                          </Box>
                        </ListItem>
                      </Tooltip>
                    )}
                  {/* apps */}
                  <Tooltip
                    label="Apps"
                    bg="gray.400"
                    hasArrow
                    placement="right"
                  >
                    <ListItem
                      fontFamily={"inter"}
                      className={`listMenuItem ${
                        activeMenu === itemsMenu?.app?.name ? "activeMenu" : ""
                      }`}
                      onClick={itemsMenu?.app?.action}
                    >
                      <Box className="listmenuItemBox">
                        <Box className="text">{itemsMenu?.app?.icon}</Box>
                      </Box>
                    </ListItem>
                  </Tooltip>
                </List>
              </Box>
            </Box>
          </Flex>
          <Box className="footer">
            <Box className="account">
              {/* minha conta */}
              <Tooltip
                label="Minha Conta"
                bg="gray.400"
                hasArrow
                placement="right"
                isDisabled={isOpen?.isOpenAccount}
              >
                <List className="listMenu">
                  <ListItem
                    ref={menuAccountMdRef}
                    fontFamily={"inter"}
                    className={`listMenuItemSub ${
                      isOpen?.isOpenAccount
                        ? "activeMenu listMenuItemSubActive"
                        : ""
                    }`}
                    onClick={itemsMenu?.account?.action}
                  >
                    <Popover
                      placement="right-end"
                      gutter={0}
                      trigger="click"
                      isOpen={
                        isOpen?.isOpenPopOver?.name ===
                          itemsMenu?.account?.name &&
                        isOpen?.isOpenPopOver?.state
                      }
                    >
                      <PopoverTrigger>
                        <Box className="listmenuItemBox">
                          <Box className="text">{itemsMenu?.account?.icon}</Box>
                          <Box
                            className={
                              isOpen?.isOpenAccount ? "iconActive" : "icon"
                            }
                          >
                            {isOpen?.isOpenAccount ? (
                              <CaretLeft
                                size={10}
                                color="#004099"
                                weight="bold"
                              />
                            ) : (
                              <CaretRight
                                size={10}
                                color="#666666"
                                weight="bold"
                              />
                            )}
                          </Box>
                        </Box>
                      </PopoverTrigger>
                      <CustomPopoverContent>
                        <PopoverBody sx={{ padding: "0px" }}>
                          <CustomBoxSub>
                            {/* btns submenu account */}
                            {Object.values(itensSubMenuAccount()).map(
                              (item: ObjectMenuProps, index) =>
                                returnBtnSubmenuAccount(item, index)
                            )}
                          </CustomBoxSub>
                        </PopoverBody>
                      </CustomPopoverContent>
                    </Popover>
                  </ListItem>
                </List>
              </Tooltip>
              <Box className="accountVersion">
                <Divider borderColor={"#CCE1FF"} />
                <Text
                  fontSize={"10px"}
                  fontFamily={"title"}
                  fontWeight={"bold"}
                  color={"#A3A3A3"}
                  pt={"8px"}
                >
                  V:{pack?.version}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
const CustomBoxSub = styled(Box)`
  .textSubMenuAccount {
    width: 100%;
    height: 40px;
    padding-left: 10px;
  }
`;

const CustomPopoverContent = styled(PopoverContent)`
  background-color: #f3f8fc;
  border-radius: unset;
  border: 0px;
  width: 220px;
  :focus {
    box-shadow: 1px 3px 6px 1px #0000001a;
    outline: 0;
  }
`;



